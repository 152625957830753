import React from "react";

import modStyl from './block-b.module.scss'
import DonutChart from "../donut-chart";
import Cell from "../cell";
import { ChartDataResult } from "../../../../../../../services/data/dashboard";

type Props = {
  dataSource: {
    page: ChartDataResult['page']
    schedule: ChartDataResult['schedule']
    visit: ChartDataResult['visit']
  }
}

const BlockB: React.FC<Props> = ({ dataSource })=> {
  return (
    <div className={modStyl.block}>
      <div className={modStyl.item}>
        <Cell direction="vertical" data={[
          {value: dataSource.schedule.total, name: '总必填项数'},
          {value: dataSource.schedule.finish, name: '必填项完成数'}
        ]}/>
        <DonutChart name="必填项数据完成率" data={[
          {value: dataSource.schedule.rate, name: '必填项数据完成率', center: true},
          {value: 100 - dataSource.schedule.rate, name: '必填项数据未完成率'}
        ]}/>
      </div>
      <div className={modStyl.item}>
        <DonutChart name="页面完成率" data={[
          {value: dataSource.page.rate, name: '页面完成率', center: true},
          {value: 100 - dataSource.page.rate, name: '页面未完成率'}
        ]}/>
      </div>
      <div className={modStyl.item}>
        <DonutChart name="访视完成率" data={[
          {value: dataSource.visit.rate, name: '访视完成率', center: true},
          {value: 100 - dataSource.visit.rate, name: '访视未完成率'}
        ]}/>
      </div>
    </div>
  )
}

export default BlockB