import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Input, message, Tooltip } from "antd";
import verify, { replaceFieldRemind } from "../../utils/verify";
import { saveFormData } from "../../../../services/data/DataService";
import "./decimal.less";
import ActionView from "../../com/actionView/actionView";
import formAction from "../../utils/formAction";
import mExper from "../../utils/operation.ts";
import DctOcrView from "../../com/dctOcrView/dctOcrView";
import PubSub from "pubsub-js";
import { InfoCircleOutlined } from "@ant-design/icons";

function DecimalCom(props: any, ref: any) {
  const [value, setValue] = useState<any>();
  const [data, setData] = useState<any>();
  const [formId, setFormId] = useState<any>();
  const [dataActions, setDataActions] = useState<any>([]);
  const [formIsRead, setFormIsRead] = useState<boolean>(true);
  const [timeObject, setTimeObject] = useState<any>();
  const [savedValues, setSavedValues] = useState<any>([]);
  const [updatedValue, setUpdatedValue] = useState<any>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [ocrLists, setOcrLists] = useState<any>(null);
  // 手动划词内容
  const [ocrValue, setOcrValue] = useState<any>(null);
  // 超范围异常提醒
  const [annotation, setAnnotation] = useState<any>(null);

  useEffect(() => {
    if (props.data) {
      //设置dataActions
      setDataActions(props.dataActions);
      //表单是否只读
      setFormIsRead(props.formIsRead);
      //获取当前表单的id
      setFormId(props.formId);
      //该条表单元素的数据
      setData(props.data);
      //已经保存的表单数据
      setSavedValues(props.savedValues);

      //如果是修改时
      if (props.isUpdate) {
        setIsUpdate(props.isUpdate);
      } else {
        setIsUpdate(false);
      }

      if (props.ocrLists) {
        setOcrLists(props.ocrLists);
      }

      //设置当前表单已存的value
      let currentItem = props.savedValues.find((i: any) => {
        return i.dataCode == props.data.dataCode;
      });
      if (currentItem?.value) {
        // 格式化数据
        const formatValue = verify.checkValue(currentItem.value, props.data);
        setValue(formatValue);
        setUpdatedValue(formatValue);
      } else {
        setValue(null);
        setUpdatedValue(null);
      }

      if (props.data.isAdd) {
        let Index = props.savedValues.findIndex(
          (v: any) => v.dataCode == props.data.dataCode
        );
        if (Index == -1) {
          let params = {
            formId: props.formId,
            submit: false,
            values: [
              {
                dataCode: props.data.dataCode,
                deCode: props.data.deCode,
                value: "",
                extendAttribute: "isAdd=true",
              },
            ],
          };
          formAction.saveData(params);
        }
      }

      if (
        props.formId &&
        props.data &&
        props.data.logicOperations &&
        props.data.logicOperations.length > 0
      ) {
        for (let logicOperation of props.data.logicOperations) {
          let result = mExper.handleLogicOperations(logicOperation, {
            savedValues: props.savedValues,
            data: props.data,
            propsData: props.propsData,
          });
          if (result == "null") {
            continue;
          }
          setValue(result);
          let values = [
            {
              dataCode: props.data.dataCode,
              deCode: props.data.deCode,
              value: result,
            },
          ];
          saveData(formId, values);
        }
      }

      // 超范围异常提醒
      if (props.data.remind || props.data.reminds) {
        // feature: 使用列表中的其他字段进行比较
        if (props.data.remind) {
          replaceFieldRemind(props.data.remind, props.savedValues);
        }
        //
        setAnnotation(
          verify.checkValueAnnotation(
            currentItem ? currentItem.value : value,
            props.data,
            props.formCenterCode,
            props.savedValues
          )
        );
      }
    }
  }, [props]);

  // 设置(跨表单)初始值
  // useEffect(() => {
  //     if (props.data && props.formId && props.propsData) {
  //         if (props.formId && props.data && props.data.logicOperations && props.data.logicOperations.length > 0) {
  //             let defalutValItem = (props.propsData.values || []).find((i: any) => {
  //                 return i.dataCode == props.data.dataCode
  //             });
  //             // 有默认值时填默认值
  //             if (defalutValItem && defalutValItem.value) {
  //                 setValue(defalutValItem.value);
  //                 let values = [
  //                     {
  //                         dataCode: props.data.dataCode,
  //                         deCode: props.data.deCode,
  //                         value: defalutValItem.value
  //                     }
  //                 ]
  //                 saveData(formId, values);
  //                 return;
  //             }
  //             for (let logicOperation of props.data.logicOperations) {
  //                 if (logicOperation.type == 'globalValue') {
  //                     let result = mExper.singleHandleGlobalValue(logicOperation, {
  //                         savedValues: savedValues,
  //                         data: props.data,
  //                         propsData: props.propsData
  //                     });
  //                     if (!result || result == "null") {
  //                         continue;
  //                     }
  //                     setValue(result);
  //                     let values = [
  //                         {
  //                             dataCode: props.data.dataCode,
  //                             deCode: props.data.deCode,
  //                             value: result
  //                         }
  //                     ]
  //                     saveData(formId, values);
  //                 }
  //             }
  //         }
  //     }
  // }, [props.data, props.formId, props.propsData])

  //当提交后显示的value
  const showSavedValue = () => {
    if (value) {
      return value;
    } else {
      return "未录入";
    }
  };

  const onChange = (e: any) => {
    let filterValue = verify.checkValue(e.target.value, data);
    setAnnotation(
      verify.checkValueAnnotation(
        filterValue,
        data,
        props.formCenterCode,
        props.savedValues
      )
    );
    saveItemData(filterValue);

    if (
      (props.ocrLists && props.ocrLists.length > 0 && ocrValue) ||
      filterValue == ""
    ) {
      deleteFunc();
    }
  };

  // 清除划词
  const deleteFunc = () => {
    let currentData = {
      sort: data.sort,
      dataCode: data.dataCode,
      deCode: data.deCode,
    };

    props.deleteDrawAreaFunc &&
      props.deleteDrawAreaFunc(currentData).then((res: String) => {
        console.log("删除划词", currentData, res);
      });
  };

  // 初始化的暂存数据

  const saveItemData = (filterValue: any) => {
    setValue(filterValue);

    let extendAttribute = "";
    if (props.data.isAdd) {
      extendAttribute = "isAdd=true";
    }
    let values = [
      {
        dataCode: props.data.dataCode,
        deCode: props.data.deCode,
        value: filterValue,
        extendAttribute: extendAttribute ? extendAttribute : undefined,
      },
    ];
    if (isUpdate) {
      setUpdatedValue(filterValue);
    }
    if (timeObject) {
      clearTimeout(timeObject);
    }
    let time = setTimeout(() => {
      if (!isUpdate) {
        saveData(formId, values);
      }
    }, 200);
    setTimeObject(time);
  };

  //暂存数据
  const saveData = (formId: any, values: any) => {
    let params = {
      formId: formId || props.formId,
      submit: false,
      values: values,
    };
    formAction.saveData(params);

    // props.startTimerFun();
    props.updateFun(getPure(values[0].value));

    // saveFormData(params).then((res) => {
    //     props.updateFun()
    // })
  };

  const getPure = (currentValue?: any) => {
    let _data = data || props.data;
    return {
      dataCode: _data.dataCode,
      deCode: _data.deCode,
      formId: formId || props.formId,
      value: currentValue ?? updatedValue,
    };
  };

  useImperativeHandle(ref, () => ({
    // changeVal 就是暴露给父组件的方法
    getModifyValue: getPure,
  }));

  // 获取划词信息
  const getOcrValue = (values: any) => {
    setOcrValue(values);
    data["isDelimitation"] = true;
    saveItemData(values && values.text);
  };

  // 获取划词icon信息
  const getOcrIcon = (values: any) => {
    props.getOcrIcon(values);
  };

  return (
    <div className="decimalCom">
      {/* {
                formIsRead ? <Input allowClear={true} disabled={!formIsRead} style={{ width:'100%' }} value={value} placeholder={data ? `请输入${data.label}` : ''} onChange={onChange} suffix={data && data.unit ? data.unit : ''} /> : <div>{showSavedValue()}</div>
            } */}
      <Input
        allowClear={true}
        disabled={!formIsRead}
        style={{
          width: "100%",
          color:
            annotation && annotation.status ? "red" : "rgba(0, 0, 0, 0.85)",
        }}
        value={value}
        placeholder={data ? `请输入${data.label}` : ""}
        onChange={onChange}
        prefix={
          annotation &&
          annotation.status && (
            <Tooltip title={annotation.message}>
              <InfoCircleOutlined style={{ color: "red", fontSize: 16 }} />
            </Tooltip>
          )
        }
        suffix={
          <>
            {data && data.unit ? data.unit : ""}
            <DctOcrView
              formItem={data}
              ocrLists={ocrLists}
              startFun={props.triggerDrawAreaFunc}
              cancleFun={props.cancelDrawAreaFunc}
              getOcrValue={getOcrValue}
              getOcrIcon={getOcrIcon}
              value={value}
            />
          </>
        }
      />
    </div>
  );
}

export default forwardRef(DecimalCom);
