import React from "react";

import modStyl from './block-a.module.scss'
import DonutChart from "../donut-chart";
import Cell from "../cell";
import { ChartDataResult } from "../../../../../../../services/data/dashboard";

type Props = {
  dataSource: ChartDataResult['replenish']
}

const BlockF: React.FC<Props> = ({ dataSource })=> {
  return (
    <div className={modStyl.a}>
      <Cell direction="vertical" data={[
        {value: dataSource.total, name: '数据补充录入任务数'},
        {value: dataSource.finish, name: '数据补充录入完成数'}
      ]}/>
      <div className={modStyl.a2}>
        <DonutChart name="数据补充录入完成率" data={[
          {value: dataSource.rate, name: '数据补充录入完成率', center: true},
          {value: 100 - dataSource.rate, name: '数据补充录入未完成率'}
        ]}/>
      </div>
    </div>
  )
}

export default BlockF