export enum ActionEnum {
  Move = 'move',
  Pencil = 'pencil',
  Rect = 'rect',
  Reset = 'reset',
  Download = 'download',
  Question = 'question',
  Focus = 'focus',
  Divider = 'divider',
}

export enum CommandEnum {
  Clear = 'clear',
  Close = 'close',
  Delete = 'delete',
}

export const defaultZoomValue = 50
export const defaultBrushValue = 20
export const defaultColorValue = '#0095ee'

// 创建马赛克笔刷
export const makeSquarePattern = ()=> {
  const squareWidth = 6;
  const squareDistance = 1;

  const patternCanvas = document.createElement('canvas');
  patternCanvas.width = patternCanvas.height = squareWidth + squareDistance;

  const ctx = patternCanvas.getContext('2d');
  if (ctx) {
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, squareWidth, squareWidth);
  }

  return patternCanvas
}

export const dataURLToFile = (dataURL: string, filename: string)=> {
  // data:image/jpeg;base64,xxxxxx
  const [mime, data] = dataURL.substring(5).split(';base64,');
  // 
  const bstr = atob(data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while(n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

export const loadRemoteImage = async (url: string, loading: (s: boolean)=> void): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject)=> {
    const im = new Image();

    im.src = url + '?now=' + Date.now();
    im.crossOrigin = 'anonymous';
    im.alt = ''

    loading(true)

    im.addEventListener('load', ()=> {
      loading(false)
      resolve(im)
    })

    im.addEventListener('error', (err)=> {
      reject(err)
    })
  })
}

export const takeFileName = (url: string): { filename: string, format: 'jpeg' | 'png'}=> {
  const { pathname } = new URL(url)
  const fullname = pathname.split('/').pop() as string
  const [filename, extention] = fullname.split('.')
  const format = ['jpeg', 'jpg', undefined].includes(extention) ? 'jpeg' : 'png'
  return {
    filename: `${filename}.${format === 'jpeg' ? 'jpg' : 'png'}`,
    format,
  }
}
