import React from "react";

import modStyl from './block-a.module.scss'
import DonutChart from "../donut-chart";
import Cell from "../cell";
import { ChartDataResult } from "../../../../../../../services/data/dashboard";

type Props = {
  dataSource: ChartDataResult['subject']
}

const BlockA: React.FC<Props> = ({ dataSource })=> {
  return (
    <div className={modStyl.a}>
      <Cell direction="vertical" data={[
        {value: dataSource.total, name: '患者总数'},
        {value: dataSource.join, name: '入组总数'}
      ]}/>
      <div className={modStyl.a2}>
        <DonutChart name="患者入组率" data={[
          {value: dataSource.rate, name: '患者入组率', center: true},
          {value: 100 - dataSource.rate, name: '其他'}
        ]}/>
      </div>
    </div>
  )
}

export default BlockA