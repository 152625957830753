import { requestGetRes, requestPostRes } from "../EdcService";

export type PaginateResult<T> = {
  code: number
  message?: string
  data: {
    page: number
    pageSize: number
    total: number
    rows: T[];
  }
}

export type Result<T> = {
  code: number
  message?: string
  data: T
}

//
// 获取区域
// 
export type AreaResult = {
  code: string
  name: string
  label?: string
  value?: string
  children: AreaResult[]
}
export const getAreaOptions = (projectCode: string)=> {
  return requestPostRes(`/project/dashboard/area/${projectCode}`) as Promise<Result<AreaResult[]>>
}

//
// 获取中心列表
// 
export type HospitalResult = {
  code: string
  name: string
}
export const getHospitalOptions = (projectCode: string, areaCodes: string[] = [])=> {
  return requestPostRes(`/project/dashboard/center/${projectCode}`, { areaCodes }) as Promise<Result<HospitalResult[]>>
}

//
// 获取中心列表
// 
export type DoctorResult = {
  code: string
  name: string
}
export const getDoctorOptions = (projectCode: string, areaCodes: string[] = [], centerCode = '')=> {
  return requestPostRes(`/project/dashboard/doctor/${projectCode}`, { areaCodes, centerCode}) as Promise<Result<DoctorResult[]>>
}

//
// 获取患者治疗阶段
//
export type StageResult = {
  code: string
  name: string
}
export const getStageOptions = ()=> {
  return requestGetRes('/data/dashboard/stage') as Promise<Result<StageResult[]>>
}

//
// 获取数据更新时间
//
export const getUpdateDeadline = ()=> {
  return requestGetRes('/data/dashboard/last/time') as Promise<Result<string>>
}

// 数据列表 患者层面
export type QueryParams = {
  areaCodes?: string[]
  centerCode?: string
  doctorCode?: string
  stage?: number
  subjectCode?: string
}
export type SubjectResult = {
  approve: number
  approveAgreeRate: number
  approveFinishRate: number
  approveWait: number
  areaName: string
  baseRate: number
  baseRequired: number
  baseRequiredImage: number
  centerName: string
  doctorName: string
  image: number
  imageAgree: number
  imageApprove: number
  imagePage: number
  imagePageAgree: number
  imagePageApprove: number
  imagePageRate: number
  imageRate: number
  join: string
  pageRate: number
  replenish: number
  replenishFinish: number
  replenishRate: number
  required: number
  requiredFinish: number
  requiredRate: number
  stageDetail: string
  subjectCode: string
  visitRate: number
}
export const getSubjectPaginate = (page: number, pageSize: number, projectCode: string, payload: Partial<QueryParams>)=> {
  return requestPostRes(`/data/dashboard/subject/${projectCode}`, {
    ...payload,
    page,
    pageSize
  }) as Promise<PaginateResult<SubjectResult>>
}

// 数据列表 患者层面
export type PageResult = {
  approve: number
  approveAgreeRate: number
  approveFinishRate: number
  approveWait: number
  areaName: string
  baseRate: number
  baseRequired: number
  baseRequiredImage: number
  centerName: string
  doctorName: string
  image: number
  imageAgree: number
  imageApprove: number
  imagePage: number
  imagePageAgree: number
  imagePageApprove: number
  imagePageRate: number
  imageRate: number
  join: string
  pageRate: number
  replenish: number
  replenishFinish: number
  replenishRate: number
  required: number
  requiredFinish: number
  requiredRate: number
  stageDetail: string
  subjectCode: string
  visitRate: number
}
export const getPagePaginate = (page: number, pageSize: number, projectCode: string, payload: Partial<QueryParams>)=> {
  return requestPostRes(`/data/dashboard/page/${projectCode}`, {
    ...payload,
    page,
    pageSize
  }) as Promise<PaginateResult<PageResult>>
}

export interface ChartDataResult {
  // 数据审核完成情况
  audit: {
    // 审核任务通过数
    agree: number
    // 审核任务通过率
    agreeRate: number
    // 审核任务完成数	
    finish: number
    // 审核任务完成率
    rate: number
    // 审核任务数
    total: number
    // 审核任务待审核数
    wait: number
  }
  // 基线页面完成情况
  base: {
    // 基线页面完成数
    finish: number
    // 上传必填项图片数	
    image: number
    // 基线页面完成率
    rate: number
    // 上传必填项数
    required: number
    // 基线页面数
    total: number
  }
  // 图片完成情况
  image: {
    // 审核通过数
    agree: number
    // 审核图片数	
    approve: number
    // 页面通过数
    pageAgree: number
    // 页面审核数
    pageApprove: number
    // 页面通过率
    pageRate: number
    // 上传图片的页面数
    pageTotal: number
    // 审核通过率
    rate: number	
    // 上传图片的数量
    total: number
  }
  // 页面完成情况
  page: {
    // 页面完成率
    rate: number
  }
  // 数据补充录入情况
  replenish: {
    // 补充数据完成数
    finish: number
    // 补充数据完成率
    rate: number
    // 补充数据数
    total: number
  }
  // 数据完成率
  schedule: {
    // 必填项完成数
    finish: number
    // 必填项数据完成率
    rate: number
    // 必填项数	
    total: number
  }
  // 患者治疗阶段
  stage: {
    // 需要统计的阶段的总数
    total: number
    // 	每个阶段的详细
    stages: {
      key: number
      name: string
      number: number
      rate: number
    }[]
  }
  // 患者入组情况
  subject: {
    // 入组数量
    join: number
    // 入组率
    rate: number
    // 患者总数
    total: number
  }
  // 访视完成情况
  visit: {
    // 访视完成数
    finish: number
    // 访视完成率
    rate: number
    // 访视总数
    total: number
  }
}
export const getChartData = (projectCode: string, payload: Partial<QueryParams>)=> {
  return requestPostRes(`/data/dashboard/home/${projectCode}`, payload) as Promise<Result<ChartDataResult>>
}
