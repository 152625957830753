import { Empty, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { getSubjectPaginate, QueryParams, SubjectResult } from "../../../../../services/data/dashboard";
import { DataBoardContext } from "../../index.context";

import modStyl from './patient-table.module.scss'

type Props = {

}

let i = 0

const PatientTable: React.FC<Props> = ()=> {

  const ctx = useContext(DataBoardContext)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<SubjectResult[]>([])

  const conditionRef = useRef<Partial<QueryParams>>({})

  //#region 表格列
  const columns: ColumnsType<SubjectResult> = [
    {
      width: 120,
      title: '区域',
      ellipsis: true,
      dataIndex: 'areaName',
      render: (value: string)=> value || '-'
    },
    {
      width: 120,
      title: '研究中心',
      dataIndex: 'centerName',
    },
    {
      width: 120,
      title: '研究者',
      dataIndex: 'doctorName',
    },
    {
      width: 120,
      title: '患者编号',
      dataIndex: 'subjectCode',
    },
    {
      width: 120,
      title: '治疗阶段',
      dataIndex: 'stageDetail',
      render: (value: string)=> {
        if (!value) {
          return '-'
        }
        return (
          <span className={modStyl.cells}>
            {
              value.split("\n").map((text, i)=> <em key={i}>{text.split(":").join("：")}</em>)
            }
          </span>
        )
      }
    },
    {
      width: 80,
      title: '确认入组',
      dataIndex: 'join',
    },
    {
      width: 80,
      title: '总必填项',
      dataIndex: 'required',
    },
    {
      width: 80,
      title: '必填项完成数',
      dataIndex: 'requiredFinish',
    },
    {
      width: 80,
      title: '必填项数据完成率',
      dataIndex: 'requiredRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 90,
      title: '页面完成率',
      dataIndex: 'pageRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 90,
      title: '访视完成率',
      dataIndex: 'visitRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 120,
      title: '基线页面(上传必填项图片数）',
      dataIndex: 'baseRequiredImage',
    },
    {
      width: 120,
      title: '基线页面(上传必填项数）',
      dataIndex: 'baseRequired',
    },
    {
      width: 120,
      title: '基线页面填写完成率',
      dataIndex: 'baseRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 90,
      title: '上传图片数',
      dataIndex: 'image',
    },
    {
      width: 90,
      title: '图片审核数',
      dataIndex: 'imageApprove',
    },
    {
      width: 80,
      title: '图片审核通过数',
      dataIndex: 'imageAgree',
    },
    {
      width: 80,
      title: '图片审核通过率',
      dataIndex: 'imageRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 80,
      title: '上传图片的页面数',
      dataIndex: 'imagePage',
    },
    {
      width: 120,
      title: '上传图片页面的审核数',
      dataIndex: 'imagePageApprove',
    },
    {
      width: 120,
      title: '上传图片页面的审核通过数',
      dataIndex: 'imagePageAgree',
    },
    {
      width: 120,
      title: '上传图片页面的审核通过率',
      dataIndex: 'imagePageRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 120,
      title: '数据补充录入任务数',
      dataIndex: 'replenish',
    },
    {
      width: 120,
      title: '数据补充录入完成数',
      dataIndex: 'replenishFinish',
    },
    {
      width: 120,
      title: '数据补充录入完成率',
      dataIndex: 'replenishRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 80,
      title: '数据审核任务数',
      dataIndex: 'approve',
    },
    {
      width: 120,
      title: '数据审核任务待审核数',
      dataIndex: 'approveWait',
    },
    {
      width: 120,
      title: '数据审核任务审核完成率',
      dataIndex: 'approveFinishRate',
      render: (text: string)=> `${text}%`
    },
    {
      width: 120,
      title: '数据审核任务审核通过率',
      dataIndex: 'approveAgreeRate',
      render: (text: string)=> `${text}%`
    },
  ];
  //#endregion

  const fetchPaginate = async (page = 1)=> {
    setLoading(true)
    const { data } = await getSubjectPaginate(page, pageSize, ctx.projectCode, ctx.queryParams)
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)
    setPageNum(page)
  }

  useEffect(()=> {
    fetchPaginate()
  }, [ctx.queryParams])

  return (
    <Table
      loading={loading}
      size="middle"
      rowKey={()=> `${i++}`}
      scroll={{y: window.innerHeight - 425}}
      columns={columns}
      dataSource={dataSource}
      locale={{emptyText: <Empty description="暂无数据"/>}}
      pagination={{
        onChange: (page: number)=> fetchPaginate(page),
        showTotal: total=> `共${total}条，每页${pageSize}条`,
        style: {marginBottom: 0},
        total: total,
        current: pageNum,
        pageSize: pageSize,
        hideOnSinglePage: true,
        showSizeChanger: false,
        showQuickJumper: false,
        position: ["bottomRight"]}}/>
  )
}

export default PatientTable