import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

type DataType = {
  name: string
  value: number
  center?: boolean
  label?: any
}
type Props = {
  name: string
  data?: DataType[]
  series?: echarts.PieSeriesOption
}

const makeOption = ({ name, data = []}: Props)=> {
  let normal: any = {}
  for(const item of data) {
    if (item.center) {
      // 设置居中显示百分比
      item.label = {
        show: true,
        position: 'center',
        fontSize: 26
      }
      // 设置提示框显示百分比
      normal = {
        normal: {
          formatter: '{d}%',
          position: 'center',
          show: false,
        }
      }
      break
    }
  }
  return {
    tooltip: {
      trigger: 'item'
    },
    title: {
      text: name,
      bottom: '5%',
      left: 'center',
      textStyle: { fontSize: 14 }
    },
    color: data.length === 2 ? ['#6287EC', '#F4F4F4'] : ['#6287EC', '#4AD2E7', '#EC808D', '#1DCE75', '#FEDF82'],
    series: [
      {
        name,
        data,
        radius: ['50%', '70%'],
        center: ['50%', '45%'],
        type: 'pie',
        selectedMode: 'single',
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          borderWidth: 2
        },
        emphasis: {
          label: {
            show: false,
          }
        },
        label: {
          show: false,
          ...normal
        },
        labelLine: {
          show: false,
          smooth: 0.4,
          length: 10,
        },
      }
    ]
  }
}


const DonutChart: React.FC<Props> = ({ name, data = [] })=> {
  const chartRef = useRef<HTMLDivElement>(null);

  const echartsInstance = useRef<echarts.ECharts>()


  useEffect(()=> {
    if (echartsInstance.current) {
      // echartsInstance.current.clear()
      echartsInstance.current.setOption(makeOption({name, data}))
    }
  }, [data])

  useEffect(()=> {
    if (chartRef.current) {
      echartsInstance.current = echarts.init(chartRef.current)
      
      echartsInstance.current.setOption(makeOption({name, data}))
    }
  }, [])

  return (
    <div ref={chartRef} style={{height: '100%', width: '100%'}}></div>
  )
}

export default DonutChart