const makeNewCode = (target: string, index: number) => {
  return `${target.split("#").shift()}#${index}`;
};

const handleItem = (item: any, index: number) => {
  if(item.type=='hide') {
    return
  }
  if(item.dataCode) {
    item.dataCode = makeNewCode(item.dataCode, index);
  }
  if (item.display) {
    const { target } = item.display;
    if (Array.isArray(item.display.target)) {
      item.display.target = target.map((t: string) => makeNewCode(t, index));
    } else if (typeof target === "string") {
      item.display.target = makeNewCode(target, index);
    }
  }
  if (Array.isArray(item.displays)) {
    item.displays.forEach((el: any) => {
      el.target = makeNewCode(el.target, index);
    });
  }
  if (Array.isArray(item.hides)) {
    item.hides.forEach((el: any) => {
      el.target = makeNewCode(el.target, index);
    });
  }
  if(['decimal', 'number', 'input'].includes(item.type)) {
    if(item.remind) {
      if(item.remind.type === 'range') {
        item.remind.rule = item.remind.rule.map((r: string | number) => {
          if(typeof r === 'number') {
            return r
          }
          return r.indexOf('#') === -1 ? r : makeNewCode(r, index);
        });
      }
    }
    if(Array.isArray(item.validationRules)) {
      item.validationRules.forEach((el: any) => {
        if(el.type === 'range') {
          // 特殊处理
          if(typeof el._rule === 'string') {
            el.rule = el._rule
            delete el._rule
          }
          if(typeof el.rule === 'number') {
            return
          }
          el.rule = el.rule.indexOf('#') === -1 ? el.rule : makeNewCode(el.rule, index)
        }
      })
    }
  }
};

// 处理模板(列表中的第1个)
const dealTemplateFun = (data: any) => {
  for (const item of data) {
    item.children ? dealTemplateFun(item.children) : handleItem(item, 1);
  }
};

// 处理添加的内容(列表中的其他)
const dealAddContentFun = (data: any, index: number) => {
  for (const item of data) {
    item.children
      ? dealAddContentFun(item.children, index)
      : handleItem(item, index);
  }
};

export default {
  // 限制输入是的格式为小数点前x位，小数点后x位；
  setFloat(value: any, maxLength: any, maxPrecision: any) {
    //第一个字符是小数点的情况.
    if (value !== "" && value.substr(0, 1) == ".") {
      value = "";
    }
    value = value.replace(/^0*(0\.|[1-9])/, "$1"); //解决 粘贴不生效
    value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
    value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    //设置\d出现的次数  没有配置精度默认2
    let rep = "\\d".repeat(maxPrecision ?? 2);
    //new RegExp
    let newRegExp = new RegExp("^(\\-)*(\\d+)\\.(" + rep + ").*$");
    value = value.replace(newRegExp, "$1$2.$3"); //只能输入两个小数
    if (value.indexOf(".") < 0 && value !== "") {
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) == "0" && value.length == 2) {
        value = value.substr(1, value.length);
      }
    }
    // 限制小数点前位数
    if (value.indexOf(".") !== -1) {
      if (value.indexOf(".") > maxLength) {
        value = value.substring(0, maxLength);
      }
    } else {
      value = value.substring(0, maxLength);
    }
    return value;
  },
  //addList
  addList(data: any, key: any) {
    const find = (children: any) => {
      for (let child of children) {
        if (child.id === key) {
          //模板
          let template = child.children[0];
          //要添加的内容
          let tpl = JSON.parse(JSON.stringify(template));
          // 当前索引
          const currentIdx = parseInt(child.currentIndex);
          // 下一个索引
          const nextIdx = currentIdx + 1;
          // 处理数据
          const dealNodeFun = (data: any) => {
            for (let item of data) {
              if (item.children) {
                dealNodeFun(item.children);
              } else {
                item.isAdd = true;
                handleItem(item, nextIdx);
              }
            }
          };
          dealNodeFun(tpl.children);
          child.currentIndex = nextIdx;
          child.children.push(tpl);
        } else if (child.children) {
          find(child.children);
        }
      }
    };
    find(data);
    return data;
  },
  /**
   * 删除list某一项
   * @param data formData
   * @param key   list的id
   * @param index 删除下标
   * @returns
   */
  deleteListItem(data: any, key: any, index: any) {
    let _list: any = [];
    const find = (children: any) => {
      for (let i of children) {
        if (i.id === key) {
          let data = JSON.parse(JSON.stringify(i.children));
          _list = data.splice(index, 1);
          data.splice(index, 1);
          i.currentIndex = parseInt(i.currentIndex) - 1;
          i.children = data;
        } else if (i.children) {
          find(i.children);
        }
      }
    };
    find(data);
    return {
      _data: data,
      _list: _list[0],
    };
  },

  //处理list表单相关索引数据
  dealFieldList(data: any) {
    let index = 1;
    const findList = (children: any) => {
      for (let i of children) {
        if (i.type === "list") {
          i.id = `list#${index}`;
          i.currentIndex = i.initNumber;
          index = index + 1;
        } else if (i.children) {
          findList(i.children);
        }
      }
    };
    findList(data.body);
    return data.body;
  },
  //根据values处理当前渲染list的长度
  dealFieldListByValues(data: any) {
    let values = data.values || [];
    let list: any[] = [];
    // 查找list表单列表
    const findList = (filterData: any) => {
      for (let i of filterData) {
        if (i.type === "list") {
          list.push(i);
        } else if (i.children) {
          findList(i.children);
        }
      }
    };
    findList(data.body);

    if (list.length === 0) {
      return;
    }

    const findDataCode = (data: any, key: any) => {
      let result = false;
      const dealFun = (list: any, key: any) => {
        if(result){
          return
        }
        for (let item of list) {
          if (item.dataCode) {
            const [dataCode] = item.dataCode.split("#");
            if(key === dataCode) {
              result = true;
              break;
            }
          } else if (item.children) {
            dealFun(item.children, key);
          }
        }
      };
      dealFun(data, key);
      return result;
    };

    for (const item of list) {
      for (const { dataCode } of values) {
        if (dataCode && dataCode.indexOf("#") !== -1) {
          const [pureCode, i] = dataCode.split("#");
          const index = parseInt(i);
          if (findDataCode([item], pureCode)) {
            if (
              index > parseInt(item.initNumber) &&
              index < parseInt(item.maxNumber)
            ) {
              if (index > parseInt(item.currentIndex)) {
                item.currentIndex = index;
              }
            }
          }
        }
      }
    }

    // 根据currentIndex处理list数据
    const dealList = (body: any) => {
      for (let item of body) {
        if (item.type === "list") {
          //模板
          const template = item.children[0];

          dealTemplateFun(template.children);

          //要添加的内容
          let addContent = [];
          const initNumber = parseInt(item.initNumber);
          for (let index = initNumber + 1; index <= item.currentIndex; index++) {
            const addItem = JSON.parse(JSON.stringify(template));
            dealAddContentFun(addItem.children, index);
            addContent.push(addItem);
          }
          item.children = [template, ...addContent];
        } else if (item.children) {
          dealList(item.children);
        }
      }
    };

    dealList(data.body);
  },
};
