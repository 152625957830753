import React from "react";

import modStyl from './cell.module.scss'

type DataType = {
  value: number
  name: string
}

type Props = {
  data: DataType[]
  direction?: 'horizontal' | 'vertical'
}

const Cell: React.FC<Props> = ({ data, direction = 'horizontal' })=> {
  return (
    <div className={`${modStyl.cell} ${modStyl[direction]}`}>
      {
        data.map((item, index)=> (
          <dl key={index}>
            <dd>{item.value}</dd>
            <dt>{item.name}</dt>
          </dl>
        ))
      }
    </div>
  )
}

export default Cell