import React from "react";

import modStyl from './block-d.module.scss'
import DonutChart from "../donut-chart";
import Cell from "../cell";
import { ChartDataResult } from "../../../../../../../services/data/dashboard";

type Props = {
  dataSource: ChartDataResult['image']
}

const BlockD: React.FC<Props> = ({ dataSource })=> {
  return (
    <div className={modStyl.d}>
      <div className={modStyl.d2}>
        <div className={modStyl.flex}>
          <div className={modStyl.header}>图片层级</div>
          <div className={modStyl.body}>
            <Cell direction="vertical" data={[
              {value: dataSource.total, name: '上传图片数'},
              {value: dataSource.approve, name: '审核图片数'},
              {value: dataSource.agree, name: '审核通过数'},
            ]}/>
            <DonutChart name="审核通过率" data={[
              {value: dataSource.rate, name: '审核通过率', center: true},
              {value: 100 - dataSource.rate, name: '审核未通过率'},
            ]}/>
          </div>
        </div>
        <div className={modStyl.flex}>
          <div className={modStyl.header}>页面层级</div>
          <div className={modStyl.body}>
            <Cell direction="vertical" data={[
              {value: dataSource.pageTotal, name: '上传图片的页面数'},
              {value: dataSource.pageApprove, name: '页面审核数'},
              {value: dataSource.pageAgree, name: '页面审核通过数'},
            ]}/>
            <DonutChart name="审核通过率" data={[
              {value: dataSource.pageRate, name: '审核通过率', center: true},
              {value: 100 - dataSource.pageRate, name: '审核未通过率'},
            ]}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockD