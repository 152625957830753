import React, { useMemo } from "react";
import PieChart from "../pie-chart";

import modStyl from './block-c.module.scss'
import Cell from "../cell";
import { ChartDataResult } from "../../../../../../../services/data/dashboard";

type Props = {
  dataSource: ChartDataResult['stage']
}

const BlockC: React.FC<Props> = ({ dataSource })=> {

  const series = {
    radius: '60%',
    center: ['50%', '50%'],
    label: { show: true },
    labelLine: { show: true },
  }

  const data = useMemo(()=> {
    return dataSource.stages.map((t)=> ({name: t.name, value: t.number}))
  }, [dataSource.stages])

  return (
    <div className={modStyl.c}>
      <Cell data={data}/>
      <div className={modStyl.c2}>
        <PieChart name="患者治疗阶段情况" title={false} data={data} series={series}/>
      </div>
    </div>
  )
}

export default BlockC