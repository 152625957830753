import React from "react";

import modStyl from './block-g.module.scss'
import DonutChart from "../donut-chart";
import Cell from "../cell";
import { ChartDataResult } from "../../../../../../../services/data/dashboard";

type Props = {
  dataSource: ChartDataResult['audit']
}

const BlockG: React.FC<Props> = ({ dataSource })=> {

  return (
    <div className={modStyl.g}>
      <div className={modStyl.flex}>
        <Cell data={[{value: dataSource.total, name: '数据审核任务数'}]}/>
        <DonutChart name="审核完成率" data={[
          {value: dataSource.rate, name: '审核任务完成率', center: true},
          {value: 100 - dataSource.rate, name: '审核任务未完成率'}
        ]}/>
      </div>
      <div className={modStyl.flex}>
      <Cell data={[{value: dataSource.wait, name: '待审核任务数'}]}/>
        <DonutChart name="审核通过率" data={[
          {value: dataSource.agreeRate, name: '审核通过率', center: true},
          {value: 100 - dataSource.agreeRate, name: '审核未通过率',}
        ]}/>
      </div>
    </div>
  )
}

export default BlockG