import React from "react";

import DonutChart from "../donut-chart";

import modStyl from './block-a.module.scss'
import Cell from "../cell";
import { ChartDataResult } from "../../../../../../../services/data/dashboard";

type Props = {
  dataSource: ChartDataResult['base']
}

const BlockE: React.FC<Props> = ({ dataSource })=> {
  return (
    <div className={modStyl.a}>
      <Cell direction="vertical" data={[
        {value: dataSource.image, name: '基线页面(上传必填项图片数）'},
        {value: dataSource.required, name: '基线页面(上传必填项数）'}
      ]}/>
      <div className={modStyl.a2}>
        <DonutChart name="基线页面完成率" data={[
        {value: dataSource.rate, name: '基线页面完成率', center: true},
        {value: 100 - dataSource.rate, name: '基线页面未完成率'}
      ]}/>
      </div>
    </div>
  )
}

export default BlockE