import React, { PropsWithChildren } from "react";
import modStyl from './layout.module.scss'

type ItemProps = {
  label: string
  index: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
  className?: string
}
export const Item:React.FC<PropsWithChildren<ItemProps>> = ({ label, index, className, children })=> {
  return (
    <div className={`${modStyl.item} ${modStyl[`item-${index}`]} ${className}`}>
      <div className={modStyl.header}>{label}</div>
      <div className={modStyl.body}>{children}</div>
    </div>
  )
}

type Props = {

}
const Layout:React.FC<PropsWithChildren<Props>> = ({ children })=> {
  return (
    <div className={modStyl.layout}>
      {children}
    </div>
  )
}

export default Layout