import React, { useEffect, useState } from "react"
import { Col, Empty, List, Modal, Pagination, Row, Spin, Tabs } from "antd"
import { GetFormsById, GetSubjectDetail } from '../../../../services/data/DataService';
import Form from '../../../crf_form/Index';

import modStyl from './SubjectModal.module.scss'
import storageX, { Engine } from "../../../../util/storageX";

type Props = {
  usingQuestion?: boolean
  subjectCode: string;
  onClose: ()=> void;
}
const SubjectModal: React.FC<Props> = ({usingQuestion = false, subjectCode, onClose}) => {
  const [visits, setVisits] = useState<any[]>([])
  const [visit, setVisit] = useState<any>({})
  const [tenantCode, setTenantCode] = useState<string>('')
  const [spining, setSpining] = useState(false)

  const [total, setTotal] = useState(0)

  const fetchDetail = async (id: number)=> {
   const {code, data} = await GetFormsById(id)

   const forms = data.forms || []

    setTotal(forms.length)
    
    if(forms.length == 0) {
      return
    }

    const [first] = forms

    console.log('?forms', forms)
    storageX().set('localStorage_forms', {
      activeId: first.id,
      state: first.inputState,
      forms: forms.map((t: any)=> ({
        id: t.id,
        title: t.name,
        formCode: t.code,
        visitName: t.name,
        inputState: t.inputState,
      }))
    })
  }

  useEffect(()=> {
    setSpining(true)
    GetSubjectDetail(subjectCode, {}).then(({code, data}) => {
      setSpining(false)
      if (code == 0) {
        setVisits(data.visits)
        setTenantCode(data.tenantCode)
        if(data.visits.length > 0) {
          setVisit(data.visits[0])
          fetchDetail(data.visits[0].id)
        }
      }
    })
  }, [subjectCode])


  useEffect(()=> {
    if (usingQuestion) {
      storageX(Engine.Session).remove('projectRoleCode')
    } else {
      storageX(Engine.Session).set('projectRoleCode', 'ReadOnly')
    }
  }, [])

  return (
    <Modal onCancel={onClose} centered bodyStyle={{display: 'flex', background: '#F6F7FC', padding: 0, minHeight: 780}} title="患者详情" width="90%" visible={true} footer={null} maskClosable destroyOnClose>
      <div className={modStyl.wrapper}>
        <div className={modStyl.west}>
          <ul>
            {
              visits.map(t=> (
                <li
                  key={t.id}
                  className={visit.id === t.id ? modStyl.active : ''}
                  onClick={()=> {
                    setTotal(0)
                      setVisit(t)
                      fetchDetail(t.id)
                    }}>{t.name}</li>
              ))
            }
          </ul>
          { spining && <Spin className={modStyl.spin}/> }
        </div>
        <div className={modStyl.east}>
          {
            total > 0 && (
              <Form
                closeModal={onClose}
                editModel={visit.editModel}
                textAreaMaxLen={1000}
                fromCaseAudit={true}
                tenantCode={tenantCode}
                crfParams={null}
                repeatCodes={visit.repeatSubjectCodeList}></Form>
          )
        }
        {
          total == 0 && (
            <Empty description="无表单数据" style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}/>
          )
        }
        </div>
      </div>
    </Modal>
  )
}
export default SubjectModal