import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

type DataType = {
  name: string
  value: number
  center?: boolean
  label?: any
}
type Props = {
  name: string
  title?: string | false
  data?: DataType[]
  series?: echarts.PieSeriesOption
}

const makeOption = ({ name, data = []}: Props)=> {
  return {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name,
        data,
        radius: '60%',
        center: ['50%', '50%'],
        type: 'pie',
        selectedMode: 'single',
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          borderWidth: 2
        },
        emphasis: {
          label: {
            show: false,
          }
        },
        label: {
          show: true,
        },
        labelLine: {
          show: true,
          smooth: 0.4,
          length: 10,
        },
      }
    ]
  }
}

const PieChart: React.FC<Props> = ({ name, data = []})=> {

  const chartRef = useRef<HTMLDivElement>(null);

  const echartsInstance = useRef<echarts.ECharts>()

  useEffect(()=> {
    if (echartsInstance.current) {
      // echartsInstance.current.clear()
      echartsInstance.current.setOption(makeOption({name, data}))
    }
  }, [data])
  
  useEffect(()=> {
    if (chartRef.current) {
      echartsInstance.current = echarts.init(chartRef.current)
      echartsInstance.current.setOption(makeOption({name, data}))
    }
  }, [])

  return (
    <div ref={chartRef} style={{height: '100%', width: '100%'}}></div>
  )
}

export default PieChart