import React, { useContext, useEffect } from "react";
import Layout, { Item } from "./components/layout";
import BlockA from "./components/blocks/block-a";
import BlockE from "./components/blocks/block-e";
import BlockC from "./components/blocks/block-c";
import BlockF from "./components/blocks/block-f";
import BlockG from "./components/blocks/block-g";
import BlockD from "./components/blocks/block-d";
import BlockB from "./components/blocks/block-b";
import { ChartDataResult, getChartData } from "../../../../../services/data/dashboard";
import { DataBoardContext } from "../../index.context";
import { Spin } from "antd";

type Props = {

}

const Charts:React.FC<Props> = ()=> {

  const ctx = useContext(DataBoardContext)

  const [loading, setLoading] = React.useState<boolean>(false)

  const [complexData, setComplexData] = React.useState<ChartDataResult>()
  
  const fetchChartData = async ()=> {
    setLoading(true)
    const { code, data } = await getChartData(ctx.projectCode, ctx.queryParams)
    setLoading(false)
    setComplexData(data)
  }

  useEffect(()=> {
    fetchChartData()
  }, [ctx.queryParams])

  return (
    <div style={{height: window.innerHeight - 320, overflowY: 'auto'}}>
      <Spin spinning={loading}>
        {
          complexData && (
            <Layout>
              <Item label="患者入组情况" index={1}>
                <BlockA dataSource={complexData.subject} />
              </Item>
              <Item label="数据完成率" index={2}>
                <BlockB dataSource={{
                  page: complexData.page,
                  schedule: complexData.schedule,
                  visit: complexData.visit
                }}/>
              </Item>
              <Item label="患者治疗阶段情况" index={3}>
                <BlockC dataSource={complexData.stage}/>
              </Item>
              <Item label="图片完成情况" index={4}>
                <BlockD dataSource={complexData.image}/>
              </Item>
              <Item label="基线（筛选期）页面完成情况" index={5}>
                <BlockE dataSource={complexData.base}/>
              </Item>
              <Item label="数据补充录入完成情况" index={6}>
                <BlockF dataSource={complexData.replenish}/>
              </Item>
              <Item label="数据审核完成情况" index={7}>
                <BlockG dataSource={complexData.audit}/>
              </Item>
            </Layout>
          )
        }
      </Spin>
    </div>
  )
}

export default Charts